import React from 'react';
import { withSiteData } from 'react-static';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  color: white;
  background-size: cover;
  margin-bottom: 100px;
`;

const Name = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 4em;
  display: block;
  background: #83c0fd7d;
  padding: 10px;
  text-align: center;
  margin: 0;
  margin-top: 70px;
`;

const About = styled.div`
  font-size: 1.5em;
  font-family: 'Roboto', sans-serif;
  margin: 0.7em;
  text-align: center;
`;

const Container = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
  position: absolute; 
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;
  color: white;
`;

const Contact = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  position: absolute; 
  right: 0; 
  bottom: 0; 
  left: 0;
  text-align: center;

`;

export default withSiteData(() => (
  <Container>
    <Header>
      <Name>External Techs</Name>
    </Header>
    <About>
      <p>External Techs is software consultancy based in London.</p>
      <p>We focus on Javascript, React and Node.</p>
      <p>If you need experts in Javascript look no further and contact us.</p>
    </About>
    <Contact>
      <p>michaelmilessmith@gmail.com</p>
    </Contact>
  </Container>
));
